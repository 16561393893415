<template>
  <v-row class="d-inherit">
    <v-col
      v-if="filteredReport21.fields && filteredReport21.fields.id"
      class="d-inherit flex-0"
      cols="12"
    >
      <div class="card-header flex-1 pt-0">
        <div class="card-title">
          <!-- <h3 class="card-label">КОРПОРАТИВ КАРТА ХИСОБОТИ</h3> -->
          <h3 class="card-label">KORPORATIV KARTA HISOBOTI</h3>
        </div>

        <v-row>
          <v-col>
            <div class="font-size-h5">
              Karta №:
              <b>{{
                filteredReport21.fields.number_16_digit
                  | VMask('#### #### #### ####')
              }}</b>
            </div>

            <div class="font-size-h5">
              Shaxsiy hisob (лицевой счёт):
              <b>{{ filteredReport21.fields.bank_account }}</b>
            </div>
          </v-col>

          <v-col>
            <div class="font-size-h5">
              Karta biriktirilgan xodim:
              <b>{{ filteredReport21.fields.owner_info }}</b>
            </div>

            <div class="font-size-h5">
              Tugash davri:
              <b>
                {{
                  $dayjs(filteredReport21.fields.expire).format('DD-MM-YYYY')
                }}
              </b>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col>
      <el-table
        :data="filteredReport21.finalResult"
        border
        size="small"
        v-loading="reportsStore.table_loading"
        class="h-100"
      >
        <el-table-column label="Korporativ karta bo'yicha operatsiyalar">
          <el-table-column
            align="center"
            width="130"
            prop="oper_date"
            label="Sana"
          >
            <template slot-scope="scope">
              {{ $dayjs(scope.row.oper_date).format('DD-MM-YYYY') }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            width="180"
            prop="counteragent_name"
            label="Xizmat ko'rsatuvchi nomi"
          />

          <el-table-column
            align="center"
            width="180"
            prop="bought_product_service"
            label="Sotib olingan tovar nomi"
          />

          <el-table-column align="center" width="180" label="Avtomashina">
            <el-table-column
              align="center"
              width="180"
              prop="truck_model_brand"
              label="Turi"
            />
            <el-table-column
              align="center"
              width="180"
              prop="truck_reg_number"
              label="Davlat raqami"
            />
          </el-table-column>

          <el-table-column
            align="center"
            width="180"
            prop="amount"
            label="Summa"
          >
            <template slot-scope="scope">
              {{ scope.row.amount | toRoundFloat }}
            </template>
          </el-table-column>

          <el-table-column align="center" width="180" prop="" label="Chiqim">
            <el-table-column
              align="center"
              width="180"
              prop="total_with_nds"
              label="NDSsiz summasi"
            >
              <template slot-scope="scope">
                {{ scope.row.total | toRoundFloat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              prop="nds_percent"
              label="NDS foizi"
            />
            <el-table-column
              align="center"
              width="180"
              prop="nds_summa"
              label="NDS summasi"
            >
              <template slot-scope="scope">
                {{ scope.row.nds_summa | toRoundFloat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              prop="total_with_nds"
              label="Jami chiqim NDS bilan"
            >
              <template slot-scope="scope">
                {{ scope.row.total_with_nds | toRoundFloat }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            align="center"
            width="180"
            prop="remain_amount"
            label="Qoldiq"
          >
            <template slot-scope="scope">
              {{ scope.row.remain_amount | toRoundFloat }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport21'])
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'KorpKarta hisoboti' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  },

  filters: {
    toRoundFloat
  }
}
</script>
